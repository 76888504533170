// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {feature} from '@admin-tribe/acsc';
import {showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import {SYNC_ACTIONS} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import {
  isManualSync,
  isQueueButtonDisabled,
  isQueueableStatus,
  isStoppableStatus,
  isSyncStatusStopped,
} from 'features/settings/components/roster-sync-settings/rosterSyncUtils';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';

/**
 * Component that renders action buttons for syncs based on status and feature flag
 */
const SyncActionButtons = () => {
  const intl = useIntl();
  const {syncConfig, refreshSyncConfig} = useRosterSyncContext();
  const {putRosterSyncAction} = useEduRosterSyncV3();
  const [isLoading, setIsLoading] = useState(false);

  // Generic handler for all sync actions to reduce code duplication
  const handleSyncAction = async (syncAction, errorMessageId) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      await putRosterSyncAction({
        syncAction,
        syncId: syncConfig.syncId,
      });
      await refreshSyncConfig();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: errorMessageId,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Handler functions for specific actions
  const handleQueueNow = () =>
    handleSyncAction(SYNC_ACTIONS.QUEUE, 'settings.rosterSync.error.queueSync');

  const handleStopSync = () =>
    handleSyncAction(SYNC_ACTIONS.STOP, 'settings.rosterSync.error.stopSync');

  const handleRestartSync = () =>
    handleSyncAction(SYNC_ACTIONS.RESUME, 'settings.rosterSync.error.restartSync');

  // For the original UI (feature flag disabled)
  // This can be removed once temp_roster_sync_v3_code_freeze is removed
  if (feature.isDisabled('temp_roster_sync_v3_code_freeze')) {
    const isQueueButtonEnabled = isQueueableStatus(syncConfig.status);

    return (
      <Button
        data-testid="queue-sync-button"
        isDisabled={!isQueueButtonEnabled || isLoading}
        onPress={handleQueueNow}
        variant="primary"
      >
        <FormattedMessage id="settings.rosterSync.details.queueNow" />
      </Button>
    );
  }

  // Determine which buttons to show based on sync schedule and status
  const isManualSyncType = isManualSync(syncConfig.syncSchedule);

  // For the new UI (temp_roster_sync_v3_code_freeze is enabled)
  // Queue button is shown for manual syncs regardless of status
  // or for scheduled syncs when status is IDLE or SCHEDULED
  const showQueueButton = isManualSyncType || isQueueableStatus(syncConfig.status);

  // Only show stop/restart buttons for scheduled syncs
  const showStopButton = !isManualSyncType && isStoppableStatus(syncConfig.status);
  const showRestartButton = !isManualSyncType && isSyncStatusStopped(syncConfig.status);

  // Button disabled states
  const queueButtonDisabled =
    isQueueButtonDisabled(syncConfig.syncSchedule, syncConfig.status) || isLoading;

  return (
    <>
      {showQueueButton && (
        <Button
          data-testid="queue-sync-button"
          isDisabled={queueButtonDisabled}
          onPress={handleQueueNow}
          variant="primary"
        >
          <FormattedMessage id="settings.rosterSync.details.queueNow" />
        </Button>
      )}
      {showStopButton && (
        <Button
          data-testid="stop-sync-button"
          isDisabled={isLoading}
          onPress={handleStopSync}
          variant="primary"
        >
          <FormattedMessage id="settings.rosterSync.details.stopSync" />
        </Button>
      )}
      {showRestartButton && (
        <Button
          data-testid="restart-sync-button"
          isDisabled={isLoading}
          onPress={handleRestartSync}
          variant="primary"
        >
          <FormattedMessage id="settings.rosterSync.details.restartSync" />
        </Button>
      )}
    </>
  );
};

export default SyncActionButtons;
