// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {feature} from '@admin-tribe/acsc';
import {showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {ActionMenu, Item, Text} from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Pause from '@spectrum-icons/workflow/Pause';
import Play from '@spectrum-icons/workflow/Play';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useRosterSyncContext} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import {
  PROVIDER_CONFIGS,
  SYNC_ACTIONS,
  SYNC_STATUSES,
} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import {goToProviderPortal} from 'features/settings/components/roster-sync-settings/rosterSyncUtils';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';

const ACTIONS = {
  GO_TO_PORTAL: 'GO_TO_PORTAL',
  PAUSE_SYNC: 'PAUSE_SYNC',
  RESUME_SYNC: 'RESUME_SYNC',
};

const RosterSyncActionMenu = () => {
  const intl = useIntl();
  const {syncConfig, refreshSyncConfig} = useRosterSyncContext();
  const {putRosterSyncAction} = useEduRosterSyncV3();

  // Check if the feature flag is enabled
  const isFeatureFlagEnabled = feature.isEnabled('temp_roster_sync_v3_code_freeze');

  // If the feature flag is enabled, we don't need the pause/resume buttons in the menu
  if (isFeatureFlagEnabled) {
    // Simplified action handling for new UI
    const handleAction = (action) => {
      if (action === ACTIONS.GO_TO_PORTAL) {
        goToProviderPortal(syncConfig.rosterSource);
      }
    };

    return (
      <ActionMenu isQuiet onAction={handleAction}>
        <Item key={ACTIONS.GO_TO_PORTAL}>
          <Text>
            <FormattedMessage
              id="settings.rosterSync.actionMenu.goToProviderPortal"
              values={{
                provider: intl.formatMessage({id: PROVIDER_CONFIGS[syncConfig.rosterSource].name}),
              }}
            />
          </Text>
          <LinkOut />
        </Item>
      </ActionMenu>
    );
  }

  // Original functionality for when the feature flag is not enabled
  // This can be removed once temp_roster_sync_v3_code_freeze is removed
  // Check if the sync can be paused (only QUEUED, RUNNING, or SCHEDULED)
  const canPause =
    syncConfig.status === SYNC_STATUSES.QUEUED ||
    syncConfig.status === SYNC_STATUSES.RUNNING ||
    syncConfig.status === SYNC_STATUSES.SCHEDULED;

  // Check if the sync can be resumed (only when STOPPED)
  const canResume = syncConfig.status === SYNC_STATUSES.STOPPED;

  // Determine which keys should be disabled
  const disabledKeys = [];
  if (!canPause) {
    disabledKeys.push(ACTIONS.PAUSE_SYNC);
  }
  if (!canResume) {
    disabledKeys.push(ACTIONS.RESUME_SYNC);
  }

  const handleAction = async (action) => {
    switch (action) {
      case ACTIONS.GO_TO_PORTAL:
        goToProviderPortal(syncConfig.rosterSource);
        break;
      case ACTIONS.PAUSE_SYNC:
        try {
          await putRosterSyncAction({
            syncAction: SYNC_ACTIONS.STOP,
            syncId: syncConfig.syncId,
          });
          await refreshSyncConfig();
        } catch (error) {
          showErrorToast(
            intl.formatMessage({
              id: 'settings.rosterSync.error.pauseSync',
            })
          );
        }
        break;
      case ACTIONS.RESUME_SYNC:
        try {
          await putRosterSyncAction({
            syncAction: SYNC_ACTIONS.RESUME,
            syncId: syncConfig.syncId,
          });
          await refreshSyncConfig();
        } catch (error) {
          showErrorToast(
            intl.formatMessage({
              id: 'settings.rosterSync.error.resumeSync',
            })
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <ActionMenu disabledKeys={disabledKeys} isQuiet onAction={handleAction}>
      <Item key={ACTIONS.PAUSE_SYNC}>
        <Text>
          <FormattedMessage id="settings.rosterSync.actionMenu.pauseSync" />
        </Text>
        <Pause />
      </Item>
      <Item key={ACTIONS.RESUME_SYNC}>
        <Text>
          <FormattedMessage id="settings.rosterSync.actionMenu.resumeSync" />
        </Text>
        <Play />
      </Item>
      <Item key={ACTIONS.GO_TO_PORTAL}>
        <Text>
          <FormattedMessage
            id="settings.rosterSync.actionMenu.goToProviderPortal"
            values={{
              provider: intl.formatMessage({id: PROVIDER_CONFIGS[syncConfig.rosterSource].name}),
            }}
          />
        </Text>
        <LinkOut />
      </Item>
    </ActionMenu>
  );
};

export default RosterSyncActionMenu;
