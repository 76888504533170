// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit tests will be added later
// istanbul ignore file

import {feature} from '@admin-tribe/acsc';
import {showError} from '@admin-tribe/acsc-ui';
import {DialogContainer} from '@adobe/react-spectrum';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import {RosterSyncContextProvider} from 'features/settings/components/roster-sync-settings/RosterSyncContext';
import RosterSyncSteppedModal from 'features/settings/components/roster-sync-settings/RosterSyncSteppedModal';
import {ROSTER_SYNC_STEPS} from 'features/settings/components/roster-sync-settings/rosterSyncConstants';
import useEduRosterSyncV3 from 'features/settings/hooks/api/useEduRosterSyncV3';
import RosterSyncPageContent from 'features/settings/pages/roster-sync-page/RosterSyncPageContent';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

/**
 * Defines the roster sync settings page under the settings tab.
 */
const RosterSyncPage = () => {
  const [showRosterSync, setShowRosterSync] = useState(false);
  const [provider, setProvider] = useState();
  const [authData, setAuthData] = useState();
  const [integrations, setIntegrations] = useState([]);
  const intl = useIntl();
  const {rosterSyncV3} = useLoaderData();
  const {getAccessToken, getRosterTenants} = useEduRosterSyncV3();

  const [syncConfig, setSyncConfig] = useState({
    ...rosterSyncV3.syncConfig,
    rosterSource: rosterSyncV3.syncConfig?.rosterSource?.toLowerCase(),
  });

  // Check for in-progress EDU login
  useEffect(() => {
    if (ExternalAuthService.isLoginInProgress()) {
      const stateData = ExternalAuthService.getStateData();
      if (stateData.isEduLogin) {
        const getAuthData = async () => {
          try {
            // After a successful login, we get the auth code from the URL
            const loginInfo = ExternalAuthService.completeAuthentication();

            // Get the auth code based on feature flag state
            // When temp_external_login_refactor is enabled, code is a direct property
            // When disabled, we need to use the URLSearchParams get method
            const code = feature.isEnabled('temp_external_login_refactor')
              ? loginInfo.queryData.code
              : loginInfo.queryData.get('code');

            // Get the correct provider based on feature flag state
            // When temp_external_login_refactor is enabled, we use stateData.provider
            // When disabled, we use stateData.syncType
            const syncProvider = stateData.provider || stateData.syncType;

            if (!code || !syncProvider) {
              throw new Error('Missing authentication parameters');
            }

            // Exchange the auth code for an access token
            const {
              data: {access_token: accessToken},
            } = await getAccessToken({
              authCode: code,
              syncProvider,
            });

            // Get districtId
            const {
              data: {districtId, integrations: districtIntegrations},
            } = await getRosterTenants({
              accessToken,
              syncProvider,
            });

            setIntegrations(districtIntegrations);
            setAuthData({accessToken, districtId});
            setProvider(syncProvider);
          } catch (error) {
            showError(
              intl.formatMessage({
                id: 'settings.sync.syncUsersModal.errors.authenticationFailed',
              })
            );
          } finally {
            // If the login is succesful, we open the modal from settings step
            // If the login fails, we show an error and open the modal from provider step
            setShowRosterSync(true);
          }
        };

        getAuthData();
      }
    }
  }, [getAccessToken, getRosterTenants, intl]);

  return (
    <RosterSyncContextProvider
      value={{authData, integrations, provider, setProvider, setSyncConfig, syncConfig}}
    >
      <RosterSyncPageContent />
      <DialogContainer onDismiss={() => setShowRosterSync(false)}>
        {showRosterSync && (
          <RosterSyncSteppedModal
            initialStepId={provider ? ROSTER_SYNC_STEPS.SETTINGS.id : ROSTER_SYNC_STEPS.PROVIDER.id}
            onClose={() => setShowRosterSync(false)}
          />
        )}
      </DialogContainer>
    </RosterSyncContextProvider>
  );
};

export default RosterSyncPage;
