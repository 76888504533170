// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {feature} from '@admin-tribe/acsc';
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import SophiaPromoBannerView from 'common/components/sophia/sophia-promo/SophiaPromoBannerView';
import useIntroductionsStats from 'common/hooks/useIntroductionsStats';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent} from 'common/services/sophia/utils/utils';
/**
 * Personalization banner for Users overview page.
 *
 * Uses:
 * - Sophia Promo Component - common/components/sophia/sophia-promo
 * - Sophia Content Model - https://author-p22655-e59433.adobeaemcloud.com/mnt/overlay/dam/cfm/models/editor/content/editor.html/conf/adminconsole/settings/dam/cfm/models/sophia-modal-v121
 *
 * @returns If qualified in a campaign, shows the sophia-promo banner style
 */
const SophiaUsersBannerSection = () => {
  const [payload, setPayload] = React.useState(null);
  const [sophiaAnalytics, setSophiaAnalytics] = React.useState(null);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const [actionableIntroductionsCount, setActionableIntroductionsCount] = React.useState(0);
  const {data: introductionsData} = useIntroductionsStats();

  // load the personalization banner on mount
  useEffect(() => {
    const introductionsCount = feature.isEnabled('temp_tno_priority_introductions')
      ? introductionsData?.actionablePriorityIntroductionsCount || 0
      : introductionsData?.actionableIntroductionsCount || 0;
    setActionableIntroductionsCount(introductionsCount);

    // We are potentially hijacking the contextual param 1pic to pass slightly diff variations of the count, this is specifically for M3 launch
    const contextualParamsOptions = {
      '1pic': introductionsCount || 0,
    };

    const fetchData = async () => {
      const sophiaPayload = await sophiaHelper.getSophiaContent({
        contextualParamsOptions,
        surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_USERS,
      });

      // if no payload content, do nothing, component will not render
      if (
        !sophiaPayload ||
        !Array.isArray(sophiaPayload.items) ||
        !sophiaPayload.items[0]?.content
      ) {
        return;
      }

      // get the payload data
      const sophiaPayloadItem = sophiaPayload.items[0];

      // set the transformed content
      const mappedPayload = mapPromoContent(JSON.parse(sophiaPayloadItem.content));
      setPayload(mappedPayload);

      // set the analytics data
      const analytics = sophiaHelper.getSophiaPromoAnalytics(sophiaPayloadItem, mappedPayload);
      setSophiaAnalytics(analytics);

      // dispatch analytics
      sophiaHelper.dispatchSophiaPromoLoadEvent(analytics);
    };

    fetchData();
  }, [introductionsData]);

  // render nothing if there is no data from sophia, introductionsData is needed for contentTokens
  if (!payload || !introductionsData) {
    return null;
  }

  // set dismissible flag
  const onDismiss = payload?.dismissible ? () => setIsDismissed(true) : null;

  return (
    <View data-testid="sophia-users-banner-section" isHidden={isDismissed}>
      <SophiaPromoBannerView
        contentTokens={{pendingIntroductionsCount: actionableIntroductionsCount}}
        onDismiss={onDismiss}
        payload={payload}
        sophiaAnalytics={sophiaAnalytics}
      />
    </View>
  );
};

SophiaUsersBannerSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaUsersBannerSection;
